/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import Logo from '../../assets/images/commentArtboard 1@0.5x.png'
import { Dialog, Classes } from '@blueprintjs/core'
import CustomContactForm from '../../components/Form/CustomContactForm'

export class Footer extends Component {
  constructor(props) {
    super(props)
    require('./footer.scss')
    this.state = {
      isOpen: false,
      popupIcon: '',
      popupHead: '',
      popupBody: '',
    }
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }
  componentDidMount() {
    // Status and Dialog
    if (this.props.openStatus && this.props.openDialog)
      this.setState({
        isOpen: this.props.openStatus,
        popupBody: this.props.openDialog,
      })
  }

  handleOpen(type) {
    this.setState({
      isOpen: true,
    })
    switch (type) {
      case 'privacy':
        this.setState({
          popupIcon: 'info-sign',
          popupHead: 'Privacy Policy',
          popupBody: type,
        })
        break
      case 'terms':
        this.setState({
          popupIcon: 'info-sign',
          popupHead: 'Terms of Use',
          popupBody: type,
        })
        break
      case 'service':
        this.setState({
          popupIcon: 'info-sign',
          popupHead: 'Terms of Service',
          popupBody: type,
        })
        break
      case 'contact':
        this.setState({
          popupIcon: 'phone',
          popupHead: 'Contact Us',
          popupBody: type,
        })
        break
      default:
        break
    }
  }

  handleClose() {
    this.setState({
      isOpen: false,
    })
  }

  render() {
    let year = new Date().getFullYear()
    return (
      <div>
        <div className="footer-background">
          <a href="/" className="footer-logo">
            <img src={Logo} alt="footer logo" />
          </a>
          <div className="footer-top">
            <div className="container">
              <div className="row mb-md-5 mb-3">
                <ul className="footer-widget additional-info">
                  <li>
                    <a href={null} onClick={() => this.handleOpen('terms')}>
                      Terms of Use
                    </a>
                  </li>
                  <li>
                    <a href={null} onClick={() => this.handleOpen('privacy')}>
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a href={null} onClick={() => this.handleOpen('service')}>
                      Terms of Service
                    </a>
                  </li>
                  <li>
                    <a href={null} onClick={() => this.handleOpen('contact')}>
                      Contact us
                    </a>
                  </li>
                </ul>
              </div>
              <div className="row">
                <div className="footer-widget about_widget col-12 row">
                  {/*<ul className="social-icon mb-md-0 mb-3">
                                        <li>
                                            <a href="https://www.linkedin.com/in/robertsliff" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-linkedin-in"></i>
                                            </a>
                                        </li>
                                        <li><a href={null}><i className="fab fa-twitter"></i></a></li>
                                        <li><a href={null}><i className="fab fa-facebook-f"></i></a></li>
                                    </ul>*/}
                  <div className="copyright-inner">© {year} TextLegal. All rights reserved.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dialog-container">
          <Dialog className="popup-container" icon={this.state.popupIcon} onClose={this.handleClose} title={this.state.popupHead} {...this.state}>
            <div className={Classes.DIALOG_BODY}>
              {this.state.popupBody === 'privacy' ? (
                <div className="privacy">
                  <div className="entry-content">
                    <h2>TextLegal Privacy Policy</h2>
                    <p>Last modified: 11/1/2024</p>
                    {/* <br></br> */}
                    <h4>Introduction</h4>
                    <p>
                      TEXTLEGAL, LLC ( <span>"TEXTLEGAL"</span> or <span>“we”</span>) respect the privacy of you (the "Company") and the users of our Application and the Services ("users"; Company and
                      users may be collectively reffered to as "you") and are committed to protecting it through our compliance with this policy. This policy describes the types of information we may
                      collect from you or that you may provide when you use the TEXTLEGAL’s keyword short code SMS forwarding services (the “Services”) or the TEXTLEGAL application (including all
                      related documentation, the <span>“Application”</span> ) and our practices for collecting, using, maintaining, protecting, and disclosing that information.
                    </p>
                    <br></br>
                    <h5>This policy applies to information we collect:</h5>
                    <ul>
                      <li> • Through the Services or the Application.</li>
                      <li> • In email, text, and other electronic messages sent through the Services and the Application.</li>
                    </ul>
                    <br></br>
                    <p>
                      Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and
                      practices, your choice is to not download the Application or use the Services. By downloading the Application or using the Services, you agree to this Privacy Policy. This policy
                      may change from time to time (see <span> Changes to Our Privacy Policy </span> below). Your continued use of the Application or the Services after we make changes is deemed to be
                      acceptance of those changes, so please check the policy periodically for updates.
                    </p>
                    <h4> Information We Collect About You and How We Collect It </h4>
                    <br></br>
                    <p>We collect several types of information from and about you, including information:</p>
                    <ol>
                      <li>
                        By which you may be personally identified, such as name, postal address, e-mail address, telephone number, location, business name, credit card (
                        <span>“personal information” </span>);{' '}
                      </li>
                      <li>Collected automatically when you use the Application or the Services; and/or,</li>
                      <li>Provided directly from you when you provide it to us, such as when you register for an account on the Application.</li>
                      <li>
                        Your messages using our Services are not stored on your own device; they are stored on TextLegal's server. We retain your text messages in the ordinary course of providing our
                        Services to you and we retain them for a period of thirty(30) days unless you delete them sooner.
                      </li>
                    </ol>
                    <br></br>
                    <h4>Information You Provide to Us </h4>
                    <br></br>
                    <p>The information we collect on or through our Application or the Services may include:</p>
                    <ol>
                      <li>
                        Information that you provide by registering for the Services. This includes information provided at the time of registering to use the Services or the Application or any other
                        information the Application collects that is defined as personal or personally identifiable information under an applicable law. We may also ask you for information when you
                        report a problem with the Services or the Application
                      </li>
                      <li>Records and copies of your correspondence (including email addresses), if you contact us.</li>
                      <li>Details of transactions you carry out through the Services or the Application.</li>
                    </ol>
                    <br></br>
                    <h4>Automatic Information Collection and Tracking</h4>
                    <br></br>
                    <p>When you download, access, and use the Application, it may use technology to automatically collect:</p>
                    <ol>
                      <li>
                        {' '}
                        <span>Usage Details. </span> When you access and use the Application, we may automatically collect certain details of your access to and use of the Application, including
                        traffic data, location data, logs, and other communication data and the resources that you access and use on or through the Application or the Services.
                      </li>
                      <li>
                        {' '}
                        <span>Device Information.</span> We may collect information about your mobile device and internet connection, including the device’s unique device identifier, IP address,
                        operating system, browser type, mobile network information, and the device’s telephone number.
                      </li>
                      <li>
                        <span>Stored Information and Files.</span> The Application also may access metadata and other information associated with other files stored on your device. This may include,
                        for example, photographs, audio and video clips, personal contacts, and address book information.
                      </li>
                      <li>
                        {' '}
                        <span>Location Information. </span>This Application collects real-time information about the location of a users’ device. For example, we collect information on the ZIP Code
                        from which users send texts and SMS messages through the Application and the Services. If a user does not want us to collect this information do not download the Application or
                        delete it from the user’s device. Note, however, that opting out of the Application’s collection of location information may cause all or parts of the Application to become
                        inaccessible or not function properly.
                      </li>
                    </ol>
                    <br></br>
                    <h4>How We Use Your Information</h4>
                    <br></br>
                    <p>We use information that we collect about you or that you provide to us, including any personal information:</p>
                    <ol>
                      <li>To provide you with Services or information that you request from us, including to set up your user account.</li>
                      <li>To transmit messages from and to users in the Company through the Application and the Services.</li>
                      <li>To fulfill any other purpose for which you provide it.</li>
                      <li>To provide you with notices about your account, including expiration and renewal notices.</li>
                      <li>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.</li>
                      <li>To notify you about changes to the Application or Services.</li>
                      <li>In any other way we may describe when you provide the information.</li>
                      <li>For any other purpose with your consent.</li>
                    </ol>
                    <p>
                      If credit card information is provided to us, this information is sent to a third party payment processor for the sole purpose of processing payments for our Services or products
                      that you order, and credit card information is not stored in our systems;
                    </p>
                    <br></br>
                    <p>
                      The ownership of any information communicated to TEXTLEGAL for text messaging and/or storage purposes will remain with the user, and we will store such information securely in
                      accordance with our security policy and the law.
                    </p>
                    <br></br>
                    <p>
                      TEXTLEGAL is not responsible for the text or messages sent by Company or users that are using TEXTLEGAL Application or Services. It is the sole responsibility of the Company to
                      acquire proper permission to send text messages and/or MMS messages to its users.
                    </p>
                    <br></br>
                    <p>
                      We may also use your information to contact Company about our own and third-parties’ goods and services that may be of interest to Company. If Company does not want us to use
                      Company's information in this way, please contact us at <a href="mailto:support@textlegal.net"> support@textlegal.net. </a>. For more information, see{' '}
                      <span>Choices About How We Use and Disclose Your Information </span>below.
                    </p>
                    <h4>Disclosure of Your Information</h4>
                    <br></br>
                    <p>We may disclose aggregated information about you, and information that does not identify any individual, without restriction.</p>
                    {/* <br></br> */}
                    <p>We may disclose personal information that we collect, or you provide as described in this privacy policy:</p>
                    <ol>
                      <li>To our subsidiaries and affiliates.</li>
                      <li>To contractors, service providers, and other third parties we use to support our business.</li>
                      <li>
                        To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of TEXTLEGAL, LLC’s
                        assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by TEXTLEGAL, LLC about our Application
                        users is among the assets transferred.
                      </li>
                      <li>
                        To third parties to market their products or services to you if you have not opted out of these disclosures. We contractually require these third parties to keep personal
                        information confidential and use it only for the purposes for which we disclose it to them. For more information, see{' '}
                        <span> Choices About How We Use and Disclose Your Information </span> below.
                      </li>
                      <li>To fulfill the purpose for which you provide it. </li>
                      <li>For any other purpose disclosed by us when you provide the information.</li>
                      <li>With your consent.</li>
                    </ol>
                    <p>We may also disclose your personal information:</p>
                    <ol>
                      <li>To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</li>
                      <li>
                        To enforce or apply our Terms of Use, found at <a href="https://textlegal.net/terms-of-use">https://textlegal.net/terms-of-use </a> or our Terms of Service found at{' '}
                        <a href="https://textlegal.net/terms-of-service">https://textlegal.net/terms-of-service</a> and other agreements, including for billing and collection purposes.
                      </li>
                      <li>
                        If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of TEXTLEGAL, LLC, you, or others. This includes exchanging information with
                        other companies and organizations for the purposes of fraud protection and credit risk reduction.
                      </li>
                    </ol>
                    <h4>Choices About How We Use and Disclose Your Information</h4>
                    <br></br>
                    <p>
                      We strive to provide you with choices regarding the personal information you provide to us. We have created mechanisms to provide you with the following control over your
                      information:
                    </p>
                    <ol>
                      <il>
                        <span>Tracking Technologies and Advertising. </span>You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. If you disable
                        or refuse cookies, please note that some parts of the Application may then be inaccessible or not function properly.
                      </il>
                      <li>
                        <span>Location Information.</span> Users can choose whether or not to allow the Application to collect and use real-time information about the users’ device’s location through
                        the device’s privacy settings. If you block the use of location information, some all or some parts of the Application may become inaccessible or not function properly.
                      </li>
                      <li>
                        <span>Disclosure of Your Information for Third-Party Advertising.</span> If Company does not want us to share Company's personal information with unaffiliated or non-agent
                        third parties for advertising and marketing purposes, you can opt-out by contacting us at <a href="mailto:support@textlegal.net"> support@textlegal.net. </a>
                      </li>
                      <li>
                        {' '}
                        <span>Promotional Offers from the TEXTLEGAL</span>. If Company does not want us to use Company's contact information to promote our own or third parties’ products or services,
                        Company can opt-out by contacting us at <a href="mailto:support@textlegal.net"> support@textlegal.net. </a>
                      </li>
                    </ol>
                    <p>
                      We will retain your information for as long as your account is active or as long as needed to provide you with the Services. We may also retain and use your information in order
                      to comply with our legal obligations, resolve disputes, prevent abuse, and enforce our agreements. It is TEXTLEGAL’s policy to delete personal information about account holders
                      and their users when their account is no longer active.
                    </p>
                    {/* <br></br> */}
                    <p>
                      We do not control third parties’ collection or use of your information to serve interest-based advertising. However, these third parties may provide you with ways to choose not
                      to have your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative (<span>“NAI” </span>) on the
                      NAI’s website, which can be found here <a href="https://optout.networkadvertising.org/?c=1"> https://optout.networkadvertising.org/?c=1.</a>
                    </p>
                    <br></br>
                    <p>
                      {' '}
                      California residents may have additional personal information rights and choices. Please see <span>Your California Privacy Rights</span> below for more information.
                    </p>

                    <br></br>
                    <p>
                      Nevada residents who wish to exercise their sale opt-out rights under Nevada Revised Statutes Chapter 603A may submit a request to{' '}
                      <span>
                        <a href="mailto:support@textlegal.net"> support@textlegal.net </a>
                      </span>
                      . However, please know we do not currently sell data triggering that statute’s opt-out requirements.
                    </p>
                    <h4>Accessing and Correcting Your Information</h4>
                    <br></br>
                    <p>
                      You can review and change your personal information by sending us email at <a href="mailto:support@textlegal.net"> support@textlegal.net </a> to request access to, correct, or
                      delete any personal information that you have provided to us. We cannot delete a users' personal information except by also deleting your user account. We may not accommodate a
                      request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect.
                    </p>
                    <p>
                      California residents may have additional personal information rights and choices. Please see<span> Your California Privacy Rights</span> below for more information.
                    </p>

                    <h4>Children Under the Age of 13</h4>
                    <p>
                      Our Application is not intended for children under 13 years of age. No one under age 13 may provide any information to or on the Application. We do not knowingly collect personal
                      information from children under 13. If you are under 13, do not use or provide any information on this Application or through any of its features, register on the Application,
                      make any purchases through the Application, or provide any information about yourself to us, including your name, address, telephone number, email address, or any screen name or
                      user name you may use. If we learn we have collected or received personal information from a child under 13 without verification of parental consent, we will delete that
                      information. If you believe we might have any information from or about a child under 13, please contact us at <a href="mailto:support@textlegal.net"> support@textlegal.net. </a>
                    </p>
                    <h4>Your California Privacy Rights</h4>
                    <p>
                      California’s “Shine the Light” law (Civil Code Section § 1798.83) permits users of our Application that are California residents to request certain information regarding our
                      disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please send an email to{' '}
                      <a href="mailto:support@textlegal.net"> support@textlegal.net. </a>
                    </p>
                    <h4>Data Security</h4>
                    <p>
                      We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. All information you
                      provide to us is stored on our secure servers behind firewalls. Any payment transactions will be encrypted using SSL technology.
                    </p>
                    <p>
                      The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a user name and password, you are responsible for keeping this
                      password confidential. We ask you not to share your password with anyone. Unfortunately, the transmission of information via the internet is not completely secure. Although we do
                      our best to protect your personal information, we cannot guarantee the security of your personal information transmitted through our Application, and, in particular, we do not
                      guarantee that our Application meets the security required regarding the protection of any attorney-client communications. Any transmission of personal information is at your own
                      risk and, if you have concerns regarding attorney-client communications, we recommend that after initiating an attorney-client relationship through our Application, you continue
                      any privileged communications through another application that uses encryption. You understand that electronic communications may be accessed by unauthorized parties when
                      communicated across the Internet, network communications facilities, or other electronic means. You agree that we are not responsible for any electronic communication and/or any
                      of your data which may be lost, altered, intercepted or stored without authorization during the transmission of any data whatsoever across networks not owned and/or operated by
                      us or for circumvention of any privacy settings or security measures contained on the Application.
                    </p>
                    <h4>Changes to Our Privacy Policy</h4>
                    <p>
                      We may update our privacy policy from time to time. If we make material changes to how we treat our users’ personal information, we will post the new privacy policy on this page
                      and notify you by email and/or text message to the email address and/or phone number provided by you.
                    </p>
                    <p>
                      The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable email address
                      and/or phone number for you and for periodically visiting this privacy policy to check for any changes.
                    </p>
                    <h4>Contact Information for such</h4>
                    <p>
                      To ask questions or comment about this privacy policy and our privacy practices, contact us at:<a href="mailto:support@textlegal.net"> support@textlegal.net. </a>{' '}
                    </p>
                  </div>
                </div>
              ) : this.state.popupBody === 'terms' ? (
                <div className="terms-conditions">
                  <div className="entry-content">
                    <h2>
                      <strong>
                        TextLegal Terms of Use
                        <br />
                      </strong>
                    </h2>
                    <p>Last modified: 11/1/2024</p>
                    <h4>Acceptance of the Terms of Use</h4>
                    <p>
                      These Terms of Use (the “TOU”) are a binding agreement between you (<span>”End User”</span> or <span>“you”</span>) TEXTLEGAL, LLC (<span>“Company,” “we,” </span>or{' '}
                      <span>“us”</span>).. These TOU govern your use of the TEXTLEGAL on the IOS and Android and Windows desktop platforms, (including all related documentation, the “Application”).
                      The Application is licensed, not sold, to you.
                    </p>
                    <p>
                      These TOU also govern your use of the Company’s keyword short code SMS forwarding services (the “Services”). The Services allow you to purchase on a “first come, first served”
                      basis, the exclusive right to use certain keyword(s) and short code(s), as approved by the Company, which relate to a SMS short code that allows text messages to be forwarded to
                      you in a limited geographic area as specified by the Company.
                    </p>
                    <p>
                      Please read these TOU carefully before you start to use the Services or the Application.{' '}
                      <span>
                        By using the Services or by clicking to accept or agree to the TOU when this option is made available to you, you accept and agree to be bound and abide by these TOU, our Terms
                        of Service, found at <a href="https://textlegal.net/terms-of-service"> https://textlegal.net/terms-of-service </a>, and our Privacy Policy, found at{' '}
                        <a href="https://textlegal.net/privacy-policy"> https://textlegal.net/privacy-policy </a>, incorporated herein by reference.{' '}
                      </span>{' '}
                      If you do not want to agree to these TOU, the Terms of Service or the Privacy Policy, you must not access or use the Services and must not download, install or use the
                      Application.
                    </p>
                    <p>
                      The Services and the Application are offered and available to users who are at least 18 years of age or the age of majority in their jurisdiction of residence (whichever is
                      greater) and reside in the United States or any of its territories or possessions. By using the Services or the Application, you represent and warrant that you are of legal age
                      to form a binding contract with the Company and meet all of the foregoing eligibility requirements. If you do not meet all of these requirements, you must not access or use the
                      Application or the Services. If you are agreeing to these TOU or the Privacy Policy on behalf of a company or other legal entity, you represent that you have the authority to
                      bind such entity to these terms and conditions, in which case the terms “you” or “your” shall refer to such entity. If you do not have such authority, or if you do not agree with
                      these terms and conditions, do not use this Services and do not access or use the Application.
                    </p>
                    <h4>Changes to the TOU </h4>
                    <p>
                      We may revise and update these TOU from time to time in our sole discretion upon notice to you. All changes are effective immediately and apply to all access to and use of the
                      Services and the Application thereafter. However, any changes to the dispute resolution provisions set out in the Governing Law and Jurisdiction sections below will not apply to
                      any disputes for which the parties have actual notice on or before the date of the notice of the change. Your continued use of the Services or the Application following notice of
                      the revised TOU means that you accept and agree to the changes.
                    </p>
                    <h4>License Grant</h4>
                    <p>
                      Subject to these TOU, Company grants you a limited, non-exclusive, and nontransferable license to: (a) download, install, and use the Application for your personal use on a
                      single mobile device owned or otherwise controlled by you <span>(”Mobile Device”) </span>strictly in accordance with the Application’s documentation; and (b) access, and use on
                      such Mobile Device the Services made available in or otherwise accessible through the Application, strictly in accordance with these TOU.
                    </p>
                    <p>
                      {' '}
                      <span>Licensee shall not:</span> (a) copy the Application, except as expressly permitted by this license; (b) modify, translate, adapt, or otherwise create derivative works or
                      improvements, whether or not patentable, of the Application; (c) reverse engineer, disassemble, decompile, decode, or otherwise attempt to derive or gain access to the source
                      code of the Application or any part thereof; (d) remove, delete, alter, or obscure any trademarks or any copyright, trademark, patent, or other intellectual property or
                      proprietary rights notices from the Application, including any copy thereof; (e) rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer, or otherwise make
                      available the Application, or any features or functionality of the Application, to any third party for any reason, including by making the Application available on a network
                      where it is capable of being accessed by more than one device at any time; or (f) remove, disable, circumvent, or otherwise create or implement any workaround to any copy
                      protection, rights management, or security features in or protecting the Application.
                    </p>
                    <p>
                      You acknowledge and agree that the Application is provided under license, and not sold, to you. You do not acquire any ownership interest in the Application under this agreement,
                      or any other rights thereto other than to use the Application in accordance with the license granted, and subject to all terms, conditions, and restrictions, under these TOU.
                      Company and its licensors and service providers reserve and shall retain their entire right, title, and interest in and to the Application, including all copyrights, trademarks,
                      and other intellectual property rights therein or relating thereto, except as expressly granted to you in these TOU.
                    </p>
                    <p>
                      {' '}
                      <span>Updates.</span> Company may from time to time in its sole discretion develop and provide Application updates, which may include upgrades, bug fixes, patches, other error
                      corrections, and/or new features (collectively, including related documentation,<span> “Updates” </span>). Updates may also modify or delete in their entirety certain features
                      and functionality. You agree that Company has no obligation to provide any Updates or to continue to provide or enable any particular features or functionality. Based on your
                      Mobile Device settings, when your Mobile Device is connected to the internet either: (a) the Application will automatically download and install all available Updates; or (b) you
                      may receive notice of or be prompted to download and install available Updates. You shall promptly download and install all Updates and acknowledge and agree that the Application
                      or portions thereof may not properly operate should you fail to do so. You further agree that all Updates will be deemed part of the Application and be subject to all terms and
                      conditions of this agreement.
                    </p>
                    <h4>Use of the Services</h4>
                    <p>
                      We reserve the right to withdraw or amend any Services or material we provide through the Application, in our sole discretion without notice. We will not be liable if for any
                      reason all or any part of the Services are unavailable at any time or for any period.{' '}
                    </p>
                    <p>
                      To access and use the Services, you will be asked to provide certain registration details or other information. It is a condition of your use of the Services and the Application
                      that all the information you provide on the Application or through the Services is correct, current, and complete. You agree that all information you provide to register with the
                      Services or otherwise, including, but not limited to, through the use of any interactive features on the Application, is governed by our{' '}
                      <a href="https://textlegal.net/privacy-policy"> Pirvacy Policy </a>, and you consent to all actions we take with respect to your information consistent with our Privacy Policy.
                    </p>
                    <p>
                      If you choose, or are provided with, a user name, password, or any other piece of information as part of our security procedures, you must treat such information as confidential,
                      and you must not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any other person with access to the
                      Services or this Application using your username, password, or other security information. You agree to notify us immediately of any unauthorized access to or use of your
                      username or password or any other breach of security. We have the right to disable any user name, password, or other identifier, whether chosen by you or provided by us, at any
                      time in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these TOU.
                    </p>
                    <h4>Trademarks</h4>
                    <p>
                      The Company name, the terms “TextLegal” and “TextBrand”, the TextLegal and TextBrand logos, and all related names, logos, product and service names, designs, and slogans are
                      trademarks of the Company or its affiliates or licensors. You must not use such marks without the prior written permission of the Company. All other names, logos, product and
                      service names, designs, and slogans on the Application are the trademarks of their respective owners.
                    </p>
                    <h4>Prohibited Uses</h4>
                    <p>You may use the Services and the Application only for lawful purposes and in accordance with these TOU. You agree not to use the Services or the Application:</p>
                    <ol>
                      <li>
                        In any way that violates any applicable federal, state, local, or international law or regulation (including, without limitation, any laws regarding the export of data or
                        software to and from the US or other countries) or any applicable ethical standards.
                      </li>
                      <li>
                        For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable
                        information, or otherwise
                      </li>
                      <li>
                        To impersonate or attempt to impersonate the Company, a Company employee, another user, or any other person or entity (including, without limitation, by using email addresses
                        associated with any of the foregoing).
                      </li>
                      <li>
                        To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Services or the Application, or which, as determined by us, may harm the Company or
                        users of the Services or the Application, or expose them to liability.
                      </li>
                    </ol>
                    <p>Additionally, you agree not to:</p>
                    <ol>
                      <li>
                        Use the Services or the Application in any manner that could disable, overburden, damage, or impair the site or interfere with any other party’s use of the Services or the
                        Application, including their ability to engage in real time activities through the Application.
                      </li>
                      <li>Use any device, software, or routine that interferes with the proper working of the Services or the Application.</li>
                      <li>Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful.</li>
                      <li>Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Services or the Application, the server from which the Services are provided.</li>
                      <li>Otherwise attempt to interfere with the proper working of the Services or the Application. </li>
                      <li>
                        Use the Services or the Application to upload, post, text message, email, transmit or otherwise make available any illegal contests or gambling, unsolicited or unauthorized
                        advertising, promotional materials, “junk mail,” “spam,” “chain letters,” “pyramid schemes,” or any other form of solicitation.
                      </li>
                      <li>
                        Use the Services or the Application to upload, post, text message, email, transmit or otherwise make available any adult content or content that is unlawful, harmful,
                        threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of another’s privacy, hateful, or racially, ethnically or otherwise objectionable, or
                        that you do not have a right to make available under any law or under contractual or fiduciary relationships (such as inside information, proprietary and confidential
                        information learned or disclosed as part of employment relationships or under nondisclosure agreements, or which violates the intellectual property rights of a third party.
                      </li>
                      <li>Forge headers or otherwise manipulate identifiers in order to disguise the origin of any content transmitted through the Services.</li>
                      <li>
                        Use the Services or the Application to upload, post, text message, email, transmit or otherwise make available any material that contains software viruses or any other computer
                        code, files or programs designed to interrupt, destroy or limit the functionality of any mobile telephone, mobile device, computer software or hardware or telecommunications
                        equipment.
                      </li>
                      <li>“Stalk” or otherwise harass another. </li>
                      <li>
                        Using any equipment or software that has the capacity to store or produce telephone numbers to be called, using a random or sequential number generator, in conjunction with
                        your use of the Company’s Services.
                      </li>
                      <li>Using any equipment or software that has the capacity to initiate messages without human intervention, in conjunction with your use of the Services.</li>
                      <li>Use the Services or the Application to send any calls to life-line services, such as hospitals, fire, police, 911 or utility- related telephone numbers.</li>
                      <li>Harvest, or otherwise collecting information about others, without their consent.</li>
                      <li>
                        Use the Services or the Application to mislead others as to the identity of the sender of messages, by creating a false identity, impersonating the identity of
                        someone/something else or by providing contact details at do not belong to you.
                      </li>
                      <li>Attempt to gain unauthorized access to the Services, other accounts, computer systems or networks connected to the Services, through password mining or any other means.</li>
                      <li>
                        Abuse the fair use policy with regard to unlimited keywords—that is, registering many keywords without using them for weeks or months, as is determined to be abuse in the sole
                        discretion of the Company.
                      </li>
                      <li>Engage in any other activity that the Company believes could subject it to criminal liability or civil penalty/judgment.</li>
                    </ol>
                    <h4>Geographic Restrictions</h4>
                    <p>
                      The Company is based in the State of Illinois in the United States. We provide the Services and the Application for use only by persons located in the United States. We make no
                      claims that the Services or the Application are accessible or appropriate outside of the United States. Use of the Services and the Application may not be legal by certain
                      persons or in certain countries. If you use the Services or the Application from outside the United States, you do so on your own initiative and are responsible for compliance
                      with local laws.
                    </p>
                    <h4>Disclaimer of Warranties</h4>
                    <p>
                      THE APPLICATION AND THE SERVICES ARE PROVIDED TO LICENSEE “AS IS” AND WITH ALL FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE
                      LAW, COMPANY, ON ITS OWN BEHALF AND ON BEHALF OF ITS AFFILIATES AND ITS AND THEIR RESPECTIVE LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS,
                      IMPLIED, STATUTORY, OR OTHERWISE, WITH RESPECT TO THE SERVICES AND THE APPLICATION, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
                      AND NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE, OR TRADE PRACTICE. WITHOUT LIMITATION TO THE FOREGOING, COMPANY
                      PROVIDES NO WARRANTY OR UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY KIND THAT THE SERVICES OR THE APPLICATION WILL MEET YOUR REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE
                      COMPATIBLE, OR WORK WITH ANY OTHER SOFTWARE, APPLICATIONS, SYSTEMS, OR SERVICES, OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS, OR BE ERROR-FREE, OR
                      THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED.
                    </p>
                    <p>
                      THE COMPANY SHALL NOT BE LIABLE TO YOU FOR ANY LOSS ARISING FROM ANY DATA, INSTRUCTIONS, OR MATERIAL SUPPLIED, DIGITALLY OR OTHERWISE, BY YOU OR ON ITS BEHALF WHICH IS
                      INCOMPLETE, INACCURATE, ILLEGIBLE, OUT OF SEQUENCE OR ARISING FROM LATE ARRIVAL OR NON-ARRIVAL OR IN THE WRONG FORM OR ANY OTHER FAULT BY THE CLIENT OR ON ITS BEHALF. THE COMPANY
                      IS NOT RESPONSIBLE FOR ANY MALFUNCTION, DELAY, NON-PERFORMANCE AND/OR OTHER DEGRADATION OF PERFORMANCE OF ANY OF ITS SERVICES CAUSED BY OR RESULTING FROM ANY ALTERATION,
                      MODIFICATION AND/OR AMENDMENTS DUE TO CHANGES AND SPECIFICATIONS REQUESTED OR IMPLEMENTED BY YOU WHETHER OR NOT BEYOND THE SERVICES ALREADY SUPPLIED.
                    </p>
                    <p>
                      SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS ON IMPLIED WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO SOME OR ALL OF THE
                      ABOVE EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU.{' '}
                    </p>
                    <h4>Limitation on Liability</h4>
                    <p>
                      IN NO EVENT SHALL THE COMPANY, ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE TO ANY ENTITY FOR ANY INDIRECT, SPECIAL,
                      INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF
                      BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, ARISING OUT OF OR IN ANY WAY RELATED TO THE USE OF, OR THE INABILITY TO USE, THE SERVICES OR THE
                      APPLICATION, EVEN IF THE COMPANY IS EXPRESSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE,
                      EVEN IF FORESEEABLE.
                    </p>
                    <p>
                      TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COLLECTIVE LIABILITY OF THE COMPANY AND ITS SUBSIDIARIES AND AFFILIATES, AND THEIR LICENSORS, SERVICE PROVIDERS,
                      EMPLOYEES, AGENTS, OFFICERS, AND DIRECTORS, TO ANY PARTY (REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT, OR OTHERWISE) FOR DIRECT DAMAGES EXCEED THE AMOUNT YOU HAVE
                      PAID TO THE COMPANY FOR THE APPLICABLE SERVICE IN THE LAST THREE (3) MONTHS OUT OF WHICH LIABILITY AROSE.
                    </p>

                    <h4>Indemnification</h4>
                    <p>
                      You agree to defend, indemnify, and hold harmless the Company, its affiliates, licensors, and service providers, and its and their respective officers, directors, employees,
                      contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees
                      (including reasonable attorneys’ fees) arising out of or relating to your violation of these TOU or your use of the Services or the Application.
                    </p>
                    <h5>Term and Termination</h5>
                    <p>
                      The term of this agreement commences when you download or install the Application or use the Services and will continue in effect until terminated by you or Company as set forth
                      in this Section.
                    </p>
                    <p>
                      You may terminate this agreement by deleting the Application and all copies thereof from your Mobile Device. The Company may terminate this agreement at any time without notice
                      if it ceases to support the Application, which Company may do in its sole discretion. In addition, this agreement will terminate immediately and automatically without any notice
                      if you violate any of the terms and conditions of this agreement. Upon termination: (i) all rights granted to you under this agreement will also terminate; and (ii) you must
                      cease all use of the Services and the Application and delete all copies of the Application from your Mobile Device and account. Termination will not limit any of Company’s rights
                      or remedies at law or in equity.
                    </p>
                    <h4>Governing Law and Jurisdiction</h4>
                    <p>
                      All matters relating to the Application, the Services and these TOU, and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual
                      disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of Illinois without giving effect to any choice or conflict of law
                      provision or rule (whether of the State of Illinois or any other jurisdiction).
                    </p>
                    <p>
                      Any legal suit, action, or proceeding arising out of, or related to, these TOU, the Services or the Application shall be instituted exclusively in the federal courts of the
                      United States or the courts of the State of Illinois, in each case located in the County of Cook. You waive any and all objections to the exercise of jurisdiction over you by
                      such courts and to venue in such courts.
                    </p>
                    <h4>Arbitration</h4>
                    <p>
                      At Company’s sole discretion, it may require you to submit any disputes arising from these Terms or use of the Services including disputes arising from or concerning their
                      interpretation, violation, invalidity, non-performance, or termination, to final and binding arbitration under the Commercial Arbitration Rules of the American Arbitration
                      Association ("AAA") applying Illinois law. Any such arbitration shall take place before a panel of three neutral arbitrators selected in accordance with the procedures of the
                      AAA. The arbitration proceeding shall be conducted in Chicago, Illinois. Judgment upon any award made in such an arbitration may be entered and enforced in any court of competent
                      jurisdiction.
                    </p>
                    <h4>Limitation on Time to File Claims</h4>
                    <p>
                      ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE APPLICATION OR THE SERVICES MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE
                      CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
                    </p>
                    <h4>Force Majeure</h4>
                    <p>
                      Excluding payment obligations, neither party will liable for default or delay of obligations under the terms outlined in this document if any such default or delay results from
                      conditions beyond the reasonable control of either party. Such conditions include, but are not limited to, earthquakes, flood, fire, accident, telecommunications line failures,
                      electrical outages, network failures, acts of God, or labor disputes.
                    </p>
                    <h4>Waiver and Severability</h4>
                    <p>
                      No waiver by the Company of any term or condition set out in these TOU shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or
                      condition, and any failure of the Company to assert a right or provision under these TOU shall not constitute a waiver of such right or provision. If any provision of these TOU
                      is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the
                      minimum extent such that the remaining provisions of the TOU will continue in full force and effect.
                    </p>
                    <h4>Entire Agreement</h4>
                    <p>
                      These TOU, our Terms of Service and our Privacy Policy constitute the sole and entire agreement between you and TEXTLEGAL, LLC regarding the Services and the Application and
                      supersede all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, regarding the Services and the Application.
                    </p>
                  </div>
                </div>
              ) : this.state.popupBody === 'service' ? (
                <div className="terms-conditions">
                  <div className="entry-content">
                    <h2>
                      <strong>
                        TextLegal Customer Agreement <br />
                      </strong>
                    </h2>
                    <p>Last modified: 11/1/2024</p>
                    <h4>Purchase Order</h4>
                    <p>
                      This Customer Agreement describes the relationship between TextLegal, LLC (<span>“Company,”</span>
                      <span> “we,”</span> or <span>“us”</span>), with an address at 2 Prudential Plaza, 180 N. Stetson Avenue, Suite 1300, Chicago, Illinois 60601 and the company or entity identified
                      below (<span>”Customer,”</span> <span>“you”</span> or<span> “your”</span>) (each of Company and Customer, a <span>“Party”</span>and collectively, the <span> “Parties”</span>).{' '}
                    </p>
                    <p>
                      This Agreement will become effective when this Purchase Order is executed by authorized representatives of both Parties (the <span> “Effective Date”</span>) and will include both
                      this Purchase Order and the attached Terms of Service (the <span>“Terms”</span>), which are incorporated herein.
                    </p>
                    <p>
                      <span>
                        <u>Customer Information</u>
                      </span>
                    </p>
                    <table>
                      <tr>
                        <td>
                          <p>Name:______________________________&nbsp;&nbsp;</p>
                        </td>
                        <td>
                          <p>Address:______________________________</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Phone:______________________________&nbsp;&nbsp;</p>
                        </td>
                        <td>
                          <p>________________________________________</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Email:_______________________________&nbsp;&nbsp;</p>
                        </td>
                        <td>
                          <p>________________________________________</p>
                        </td>
                      </tr>
                    </table>
                    <p>
                      <span>
                        <u>Short Code(s) and Key Word(s) Requested</u>
                      </span>
                    </p>
                    <table>
                      <tr>
                        <td colSpan={2}>
                          <p>Short Code(s):____________________________________________________________________</p>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <p>Key Word(s):______________________________________________________________________</p>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <p>
                            <u>Term:</u>_____________________________________________________________________________
                          </p>
                        </td>
                      </tr>
                    </table>
                    <p>
                      <span>
                        <u>Geographic Area:</u>
                      </span>
                    </p>
                    <p>
                      [SPECIFY ADDRESS] and the __________ mile radius surrounding it. The Geographic Area includes all zip codes within the area, including if those zip codes are beyond the
                      Geographic Area.{' '}
                    </p>
                    <p>
                      <span>Payment Terms:</span>[_____________________________________________________________________________ ].{' '}
                    </p>
                    <p>
                      <span>Nonrefundable Deposit:</span> ____________________________________________________________________
                    </p>
                    <p>
                      <span>Credit Card Payment Terms.</span> If you elect to pay via credit card, then you are responsible for either (a) enabling auto-recharge on your customer account(s) or (b)
                      ensuring that your customer account(s) has a sufficient positive balance to cover all fees due. If, for any reason, you have a negative balance on your customer account(s), then
                      we reserve the right to suspend the Services.{' '}
                    </p>
                    <p>
                      <span>Invoicing Payment Terms.</span> Payment by you for the Services will be made monthly, in advance of your receiving the Services, and pursuant to invoices sent to you via
                      email as a PDF on a monthly basis. You will pay all of the undisputed fees hereunder within five (5) days of the date of the invoice. Unless the Company and Customer agree
                      otherwise in writing, all undisputed fees due pursuant to these terms are payable in United States Dollars. Payment obligations cannot be canceled, and fees paid are not
                      refundable. If you are overdue on any payment of undisputed fees and fail to pay within 10 business days of a written notice of you overdue payment, then we may assess, and you
                      must pay a late fee. The late fee will be either 1.5% per month, or the maximum amount allowable by applicable law, whichever is less. Following the notice of non-payment, we may
                      also suspend the Services until you pay the undisputed fees due plus any late fees. If we suspend the Services for nonpayment then we will have no liability for any damage,
                      liabilities, losses (including any loss of data or profits) or any other consequences that you may incur in connection with any such suspension.
                    </p>
                    <p>
                      With the exception of the Company's Terms of Use and Privacy Policy, this Agreement supersedes in its entirety and terminates any other agreement entered into by the Parties
                      prior to the Effective Date.{' '}
                    </p>
                    <p>The Parties have caused their duly authorized representatives to execute this Agreement as of the dates set forth below. </p>
                    <table>
                      <tr>
                        <td>Customer:</td>
                        <td>TextLegal, LLC</td>
                      </tr>
                      <tr>
                        <td>
                          <p>By:__________________________________&nbsp;&nbsp;</p>
                        </td>
                        <td>
                          <p>By:__________________________________&nbsp;&nbsp;</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Name:______________________________&nbsp;&nbsp;</p>
                        </td>
                        <td>
                          <p>Name:______________________________&nbsp;&nbsp;</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Title:________________________________&nbsp;&nbsp;</p>
                        </td>
                        <td>
                          <p>Title:________________________________&nbsp;&nbsp;</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Date:_______________________________&nbsp;&nbsp;</p>
                        </td>
                        <td>
                          <p>Date:_______________________________&nbsp;&nbsp;</p>
                        </td>
                      </tr>
                    </table>
                    <h2>
                      <strong>
                        TextLegal Terms of Service <br />
                      </strong>
                    </h2>
                    <br></br>
                    <p>
                      <span>1. Services</span>
                    </p>
                    <p>
                      The TextLegal services consist of keyword short code SMS forwarding services (the “Services”). The Services allow you to purchase on a “first come, first served” basis, the
                      exclusive right to use certain keyword(s) and short code(s), as approved by the Company, which relate to a SMS short code that allows text messages to be forwarded to you in a
                      limited geographic area as specified by the Company. We will try to ensure uninterrupted and continued use of our services; however, this is largely dependent on the effective
                      functioning of network service providers’ infrastructure, network coverage and the recipients’ mobile handset. You agree that we are only a conduit and take no responsibility for
                      what is sent or where it goes. To deliver your messages, they are transmitted through a system of mobile networks or aggregators. We cannot guarantee (i) uninterrupted and
                      continued use of our Services because we do not control the mobile networks or aggregators that transmit your messages; or (ii) that the Services will work with all carriers and
                      all mobile phones. Your messages are considered delivered once the message has been transmitted from our system to our network or service provider.{' '}
                    </p>
                    <p>
                      <span>2. Your Account(s)</span>
                    </p>
                    <p>
                      You are solely responsible for all use (whether or not authorized) of the Services under your customer account(s) and any subaccount(s), including the quality and integrity of
                      your Customer Data (as defined below). You are also solely responsible for all acts and omissions of anyone who has access to or otherwise uses your customer account. You will be
                      solely responsible, at your own expense, for acquiring, installing and maintaining all hardware, software and other equipment as may be necessary for or any other user of your
                      account to connect to, access, and use the Services.{' '}
                    </p>
                    <p>
                      <span>3. Data Privacy</span>
                    </p>
                    <p>“Customer Data” consists of data and other information made available to us by or for you through the Services under these Terms.</p>
                    <ol type="A">
                      <li>
                        The terms of our Privacy Policy, found at <a href="https://textlegal.net/privacy-policy">https://textlegal.net/privacy-policy</a> , are made part hereof and shall apply when
                        personal data is being processed pursuant to the Agreement. Additionally, personal data may be disclosed in accordance with our Privacy Policy. You acknowledge that you have
                        read our Privacy Policy and understand that it is how we will collect, store, use and disclose your Customer Data including personal data. If you do not agree with our Privacy
                        Policy, then you must stop using the Services immediately.
                      </li>
                      <li>
                        B. You grant us the right to use and disclose Customer Data as necessary to (a) provide the Services under the Agreement including and this Section, including detecting,
                        preventing, and investigating security incidents, fraud, spam, or unlawful use of the Services; and (b) respond to any technical problems or your queries and ensure the proper
                        working of the Services. You acknowledge, that email and SMS are an insecure medium that is generally not encrypted in transit and security of information transmitted through
                        the internet can never be guaranteed and, accordingly, we are not responsible for any interception or interruption of any communications through the internet or for changes to
                        or loss of Customer Data in connection with the Services.{' '}
                      </li>
                    </ol>
                    <p>
                      <span>4. Trademarks</span>
                    </p>
                    <p>
                      The Company name, the terms “TextLegal” and “TextBrand”, the TextLegal and TextBrand logos, and all related names, logos, product and service names, designs, and slogans are
                      trademarks of the Company or its affiliates or licensors. You must not use such marks without the prior written permission of the Company. All other names, logos, product and
                      service names, designs, and slogans on the Application are the trademarks of their respective owners.
                    </p>
                    <p>
                      <span>5.Customer Responsibilities, Restrictions, and Requirements</span>
                    </p>
                    <p>
                      You should know that you have a number of responsibilities you must carry out and there are some restrictions on what you can do with them and requirements that you must follow
                      if you use the Services.
                    </p>
                    <ol type="A">
                      <li>
                        {' '}
                        <strong>Your Responsibilities. </strong>You will: (a) be solely responsible for all use (whether or not authorized) of the Services under your account, including for the
                        quality and integrity of Customer Data; (b) use the Services only in accordance with the Agreement and these Terms, or other applicable terms relating to the use of the
                        Services, and all applicable laws, statues, regulations and ethical rules; (c) be solely responsible for all acts, omissions and activities of your account, including
                        compliance with the Agreement, and any other terms of sales of the Services; (d) do your best to prevent unauthorized access to or use of the Services and notify us promptly of
                        any such unauthorized access or use; (e) provide reasonable cooperation regarding information requests from law enforcement, regulators, or telecommunications providers, and
                        (f) comply with any representations and warranties made under the Agreement.
                      </li>
                      <li>
                        {' '}
                        <strong>Services Usage Restrictions.</strong> You agree that your use of the Services will comply with the terms of Prohibited Use under the Terms of Use, found at
                        <a href="https://textlegal.net/terms-of-use"> https://textlegal.net/terms-of-use</a>, made part hereof.
                      </li>
                    </ol>
                    <p>
                      <span>6. Affiliates </span>
                    </p>
                    <p>
                      Affiliates means any entity or person that controls a party, is controlled by the party, or under common control with the party, such as a subsidiary, parent company, or
                      employee. The term “control” means more than 50% ownership. Your affiliates are not permitted to purchase Services using the Terms of Service that you have accepted. Instead,
                      your affiliates will need to accept the Terms themselves. If your affiliates use the Services under these Terms, then you and those affiliates will be jointly and severally
                      responsible for the acts and omissions of your affiliates, including but not limited to, their breach of these Terms. Any claim from any of your affiliates that use the Services
                      pursuant to these Terms may only be brought against us by you on your affiliates’ behalf.
                    </p>
                    <p>
                      <span>7. Fees and Taxes </span>
                    </p>
                    <ol type="A">
                      <li>
                        {' '}
                        <strong>Fees.</strong> You agree to pay fees generated under your account(s) in accordance with the rates set forth in the Purchase Order. Additionally, we will charge you if
                        you use our Services in violation of the Agreement and you shall pay any and all additional costs, fines, or penalties we incur from a governmental or regulatory body or
                        telecommunication provider or otherwise, as a result of your use of Services.
                      </li>
                      <li>
                        {' '}
                        <strong>Taxes.</strong> Unless otherwise stated in an Order Form, you shall be responsible for and shall pay all Taxes imposed on or with respect to the Services that are the
                        subject of this Agreement. “Taxes” mean all applicable federal, state and local taxes, fees, charges, telecommunication provider (e.g., carrier) surcharges or other similar
                        exactions, including, without limitation, sales and use taxes, communications service taxes, utility user’s taxes or fees, excise taxes, VAT, GST, other license or business and
                        occupations taxes, 911 taxes, franchise fees and universal service fund fees or taxes. Taxes do not include any Taxes that are imposed on or measured by our net income,
                        property tax, or payroll taxes. If you are exempt from any such Taxes for any reason, we will exempt you from such Taxes on a going-forward basis once you deliver a fully
                        executed and dated valid exemption certificate to our tax department and our tax department has approved such exemption certificate. Such exemptions should be sent directly to
                        2 Prudential Plaza, 180 N. Stetson Avenue, Suite 1300, Chicago, Illinois 60601. If for any reason a taxing jurisdiction determines that you are not exempt from any such
                        exempted Taxes and then assesses use such Taxes, you agree to promptly pay to us such Taxes, plus any applicable interest or penalties assessed. <br></br>
                        <br></br>
                        Should you be required by applicable law to withhold any tax from any payment owed to us, then you may provide us with an exemption certificate or similar document to reduce or
                        eliminate any such withholding. Upon receipt of such certificate or document, you shall thereafter reduce or eliminate, as the case may be, such withholding. You shall provide
                        us with documents evidencing your payment of any such withheld Tax to applicable tax authorities.
                      </li>
                      <li>
                        <strong>Fee Disputes.</strong> If you are disputing any fees or Taxes, you must act reasonably and in good faith and you must cooperate diligently with us to resolve the
                        dispute. You must notify us in writing if you dispute any portion of any fees paid or payable by you pursuant to these Terms. You must provide that written notice to us within
                        60 days of the date we bill you for the charge you want to dispute, and we will work together with you to resolve the dispute promptly.{' '}
                      </li>
                    </ol>
                    <p>
                      <span>8. Ownership </span>
                    </p>
                    <p>
                      You agree that we exclusively own and reserve all right, title and interest in and to the Services as listed under the Intellectual Property Rights and Trademarks defined in our{' '}
                      <a href="https://textlegal.net/terms-of-use">Terms of Use </a> made part hereof.{' '}
                    </p>
                    <p>
                      <span>9. Confidentiality</span>
                    </p>
                    <ol type="A">
                      <li>
                        <strong>Confidential Information.</strong> “Confidential Information” means any information or data, regardless of whether it is in tangible form, disclosed by either party
                        that is marked or otherwise designated as confidential or proprietary or that should otherwise be reasonably understood to be confidential given the nature of the information
                        and the circumstances surrounding disclosure. Confidential information does not include any information which: (i) publicly available through no fault of the receiving party;
                        (ii) was properly known by the receiving party, without restriction, prior to disclosure by the disclosing party; (iii) was properly disclosed to the receiving party, without
                        restriction, by another person without violation of the disclosing party’s rights; or (iv) is independently developed by the receiving party without use or reference to the
                        Confidential Information of the disclosing party.
                      </li>
                      <li>
                        <strong>Use and Disclosure.</strong> The receiving party will use the same degree of care that it uses to protect the confidentiality of its own Confidential Information of the
                        kind (but not less than reasonable care) to (i) not use any Confidential Information of the disclosing party for any purpose outside the scope of this Agreement and (ii) except
                        as otherwise authorized by the disclosing party in writing, limit access to the Confidential Information of the disclosing party to those of its and its affiliates’ employees
                        and contractors who need that access for purposes consistent with this Agreement and who are legally bound to keep such information confidential consistent with the terms of
                        this section. The Company may disclose the terms of any order or other purchase of Services to its affiliates, legal counsel and accountants without your prior written consent,
                        and the Company will remain responsible for its affiliate’s, legal counsel’s or accountant’s compliance with this section. Notwithstanding the foregoing, (x) the Company may
                        disclose the terms of this Agreement and any applicable purchase transaction to a subcontractor to the extent necessary to perform our obligations under this Agreement; and (y)
                        the Company may use and disclose your Confidential Information as necessary to provide the Services, and in each case of (x) and (y) under terms of confidentiality materiality
                        as protective as set forth herein.
                      </li>
                      <li>
                        <strong>Compelled Disclosure.</strong> The Company may disclose your Confidential Information if so required pursuant to a regulation, law or court order. We will give you
                        notice of the compelled disclosure (to the extent legally permitted). You will reimburse us for our reasonable legal fees for preparation of witnesses, deposition and testimony
                        to the extent such compelled disclosure is in connection with a lawsuit or legal proceeding to which you are a party or to the extent that fees are incurred in connection with
                        reasonable assistance we provide to you in connection with your efforts to contest disclosure.
                      </li>
                      <li>
                        <strong>Injunctive Relief.</strong> The Company and you expressly acknowledge and agree that no adequate remedy may exist at law for an actual or threatened breach of this
                        Section 10 and that, in the event of an actual or threatened breach of the provisions of this Section 10, the non-breaching party will be entitled to seek immediate injunctive
                        and other equitable relief, without waiving any other rights or remedies available to it.{' '}
                      </li>
                    </ol>
                    <p>
                      <span>11. Disclaimer of Warranties </span>
                    </p>
                    <ol type="A">
                      <li>
                        YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. THE COMPANY EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND,
                        WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.{' '}
                      </li>
                      <li>
                        THE COMPANY WILL MAKE REASONABLE EFFORTS TO MAINTAIN THE SERVICE, HOWEVER, THE COMPANY IS NOT RESPONSIBLE FOR ANY DAMAGE, LOSS OF DATA, CUSTOMER DATA, REVENUE, OR OTHER HARM TO
                        BUSINESS ARISING OUT OF DELAYS, MISDELIVERY OR NONDELIVERY OF INFORMATION, RESTRICTION OR LOSS OF ACCESS, BUGS OR OTHER ERRORS, UNAUTHORIZED USE DUE TO YOUR SHARING OF ACCESS
                        TO THE SERVICE, OR OTHER INTERACTION WITH THE SERVICE. YOU ARE RESPONSIBLE FOR MAINTAINING AND BACKING-UP YOUR DATA AND INFORMATION THAT MAY RESIDE ON THE SERVICE. THE COMPANY
                        DOES NOT WARRANT THAT (i) THE SERVICE WILL MEET YOUR SPECIFIC REQUIREMENTS; (ii) THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE; (iii) THE RESULTS THAT MAY BE
                        OBTAINED FROM THE USE OF THE SERVICE WILL BE ACCURATE OR RELIABLE; AND, (iv) ANY ERRORS IN THE SERVICE WILL BE CORRECTED.
                      </li>
                    </ol>
                    <p>
                      <span>12. Term; Termination; Suspension </span>
                    </p>
                    <ol type="A">
                      <li>
                        <strong>Term.</strong> These Terms, as may be updated from time to time, will commence on the date they are accepted by you and continue until terminated as outlined below.{' '}
                      </li>
                      <li>
                        <strong>Termination. </strong> Either party may terminate these Terms and close your customer account(s) for any reason upon 30 days written notice to the other party. The
                        Company, at its sole discretion, may terminate these Terms and close your customer account(s) in the event you commit any material breach of these Terms and fail to remedy that
                        breach within 5 days after the Company provides written notice of that breach to you. You may also terminate these Terms in the event we commit a material breach of these Terms
                        and fail to remedy that breach within 5 days after providing written notice of that breach to us. Upon termination: (i) all rights granted to you under this Agreement will also
                        terminate; and (ii) you must cease all use of the Services. Termination will not limit any of Company’s rights or remedies at law or in equity.{' '}
                      </li>
                      <li>
                        <strong>Suspension of Services. </strong> In addition to suspension of Services for non-payment of fees, we may also suspend the Services immediately upon notice for cause if
                        (i) you violate (or give us reason to believe you have violated) any provision of these Terms, our Terms of Use, or our Privacy Policy; (ii) there is reason to believe that the
                        traffic created from your use of the Services or your use of the Services is fraudulent or negatively impacting the operating capability of the Services; (iii) we determine, in
                        our sole discretion, that providing the Services is prohibited by applicable law or ethical rules, or it has become impracticable or unfeasible for any legal or regulatory
                        reason to provide the Services; (iv) subject to applicable law, upon your liquidation, commencement of dissolution proceedings, disposal of your assets or change in control, a
                        failure to continue business, assignment for the benefit of creditors, or if you become the subject of bankruptcy or similar proceeding, or (v) there is any use of the Services
                        by Customer that in our judgment threatens the security, integrity or availability of the Services. However, the Company will use commercially reasonable efforts under the
                        circumstances to (a) provide you with notice and an opportunity to remedy such violation or threat prior to any such suspension; (b) where practicable, limit the suspension
                        based on the circumstances leading to the suspension (e.g., to certain phone numbers, sub-accounts or other subset of traffic); and (c) remove the suspension as quickly as
                        reasonably practicable after the circumstances leading to the suspension have been resolved.
                      </li>
                    </ol>
                    <p>
                      <span>13. Limitation on Liability </span>
                    </p>
                    <p>
                      IN NO EVENT SHALL THE COMPANY, ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE TO ANY ENTITY FOR ANY INDIRECT, SPECIAL,
                      INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF
                      BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, ARISING OUT OF OR IN ANY WAY RELATED TO THE USE OF, OR THE INABILITY TO USE, THE SERVICES EVEN IF
                      THE COMPANY IS EXPRESSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.
                    </p>
                    <br></br>
                    <p>
                      TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COLLECTIVE LIABILITY OF THE COMPANY AND ITS SUBSIDIARIES AND AFFILIATES, AND THEIR LICENSORS, SERVICE PROVIDERS,
                      EMPLOYEES, AGENTS, OFFICERS, AND DIRECTORS, TO ANY PARTY (REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT, OR OTHERWISE) FOR DIRECT DAMAGES EXCEED THE AMOUNT YOU HAVE
                      PAID TO THE COMPANY FOR THE APPLICABLE SERVICE IN THE LAST THREE (3) MONTHS OUT OF WHICH LIABILITY AROSE.
                    </p>
                    <p>
                      <span>14. Indemnification </span>
                    </p>
                    <p>
                      You agree to defend, indemnify, and hold harmless the Company, its affiliates, licensors, and service providers, and its and their respective officers, directors, employees,
                      contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees
                      (including reasonable attorneys’ fees) arising out of or relating to your violation of these Terms of Service.
                    </p>
                    <p>
                      <span>15. Survival </span>
                    </p>
                    <p>
                      Upon termination of these Terms, or the Agreement, your payment obligations and the terms of the following Sections will survive: Section 7, Fees and Taxes; Section 8, Ownership;
                      Section 9, Confidentiality; Section 11, Disclaimer of Warranties; Section 13, Limitation on Liability; Section 14, Indemnification; Section 15, Survival; Section 18, Governing
                      Law and Jurisdiction; Section 19, Arbitration; Section 20, 20. Class Action Waiver / No Joinder of Plaintiffs; Section 21, Limitation on Time to File Claims; Section 25, Waiver
                      and Severability; and Section 26, Entire Agreement.
                    </p>
                    <p>
                      <span>16. Assignment </span>
                    </p>
                    <p>
                      You will not assign or otherwise transfer these Terms, in whole or in part, without prior written consent. Any attempt by you to assign, delegate, or transfer these Terms will be
                      void. The Company may assign these terms in whole or in part without consent.
                    </p>
                    <p>
                      <span>17. Resale of Service</span>
                    </p>
                    <p>
                      You agree not to reproduce, duplicate, copy, sell as a finished service to third party, or otherwise exploit any portion of the Service, use of the Service, or access to the
                      Service without the express permission of the Company.{' '}
                    </p>
                    <p>
                      <span>18. Governing Law and Jurisdiction</span>
                    </p>
                    <p>
                      All matters relating to the Services and these Terms, and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims),
                      shall be governed by and construed in accordance with the internal laws of the State of Illinois without giving effect to any choice or conflict of law provision or rule (whether
                      of the State of Illinois or any other jurisdiction).
                      <br></br>
                      Any legal suit, action, or proceeding arising out of, or related to, these Terms or the Services shall be instituted exclusively in the federal courts of the United States or the
                      courts of the State of Illinois, in each case located in the County of Cook. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue
                      in such courts.
                    </p>
                    <p>
                      <span>19. Arbitration</span>
                    </p>
                    <p>
                      At Company’s sole discretion, it may require you to submit any disputes arising from these Terms or use of the Services including disputes arising from or concerning their
                      interpretation, violation, invalidity, non-performance, or termination, to final and binding arbitration under the Commercial Arbitration Rules of the American Arbitration
                      Association ("AAA") applying Illinois law. Any such arbitration shall take place before a panel of three neutral arbitrators selected in accordance with the procedures of the
                      AAA. The arbitration proceeding shall be conducted in Chicago, Illinois. Judgment upon any award made in such an arbitration may be entered and enforced in any court of competent
                      jurisdiction.
                    </p>
                    <p>
                      <span>20. Class Action Waiver / No Joinder of Plaintiffs</span>
                    </p>
                    <p>
                      To the fullest extent permitted by law, you and the Company agree that any claims or controversies between the Parties must be brought against each other only on an individual
                      basis. Neither you nor the Company may bring a claim as a plaintiff or class member in a class action, consolidated action, or representative action. The arbitrator cannot
                      combine more than one person or entity’s claims into a single case and cannot preside over any class, consolidated, or representative proceeding (unless the Parties explicitly
                      agree otherwise). Moreover, the arbitrator’s decision or award in one person or entity’s case can only impact the person or entity that brought the claim, not other customers of
                      the Company; it cannot be used to decide other disputes with customers.{' '}
                    </p>
                    <p>
                      <span>21. Limitation on Time to File Claims</span>
                    </p>
                    <p>
                      ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS AND CONDITIONS OR THE SERVICES MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION
                      ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
                    </p>
                    <p>
                      <span>22. Force Majeure</span>
                    </p>
                    <p>
                      Excluding payment obligations, neither party will liable for default or delay of obligations under the terms outlined in this document if any such default or delay results from
                      conditions beyond the reasonable control of either party. Such conditions include, but are not limited to, earthquakes, flood, fire, accident, telecommunications line failures,
                      electrical outages, network failures, acts of God, or labor disputes.
                    </p>
                    <p>
                      <span>23. Notices</span>
                    </p>
                    <p>
                      Any notice required or permitted to be given hereunder will be given in writing to the party at the address specified in the Agreement by personal delivery, certified mail,
                      return receipt requested, overnight delivery by a nationally recognized carrier or by email. Billing-related notices to Customer will be addressed to the relevant billing contact
                      designated by Customer in its account. Notices to the Company shall be copied to <a href="mailto:support@textlegal.net"> support@textlegal.net. </a>
                    </p>
                    <p>
                      <span>24. Relationship</span>
                    </p>
                    <p>
                      The Company and you are independent contractors in the performance of each and every part of these Terms. Nothing in these Terms or otherwise, unless expressly provided in
                      writing, is intended to create or shall be construed as creating an employer-employee relationship or a partnership, agency, joint venture, or franchise. Each party will be
                      solely responsible for its respective employees and agents and respective labor costs and expenses arising in connection with the party’s respective employees and agents. Each
                      party will also be solely responsible for any and all claims, liabilities or damages, debts of any type that may arise on account of each of our respective activities, or those
                      of each of our respective employees or agents, in the performance of these Terms. Neither the Company nor you has the authority to commit the other in any way and will not
                      attempt to do so or imply it has the right to do so.{' '}
                    </p>
                    <p>
                      <span>25. Waiver and Severability</span>
                    </p>
                    <p>
                      No waiver by the Company of any term or condition set out in these Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or
                      condition, and any failure of the Company to assert a right or provision under these Terms shall not constitute a waiver of such right or provision. If any provision of these
                      Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the
                      minimum extent such that the remaining provisions of the Terms will continue in full force and effect.
                    </p>
                    <p>
                      <span>26. Entire Agreement</span>
                    </p>
                    <p>
                      These Terms, our Terms of Use, and our Privacy Policy constitute the sole and entire agreement between you and TEXTLEGAL, LLC regarding the Services and supersede all prior and
                      contemporaneous understandings, agreements, representations, and warranties, both written and oral, regarding the Services.
                    </p>
                  </div>
                </div>
              ) : this.state.popupBody === 'contact' ? (
                <section className="contact-form-area" id="contact">
                  <div className="container">
                    <CustomContactForm onClose={this.handleClose} />
                  </div>
                </section>
              ) : (
                ''
              )}
            </div>
          </Dialog>
        </div>
      </div>
    )
  }
}

export default Footer
